import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Hotjar from "@hotjar/browser";
// const express = require('express');
// const path = require('path');

// App.use(express.static(__dirname));

// App.get("/*", function(req, res) {
//   res.sendFile(path.join(__dirname, "index.html"));
// });

const siteId = 3599598;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
