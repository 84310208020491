import locales from "dayjs/locale.json";

const supportLocales = locales;

export const getLocale = () => {
  const browserLanguage = navigator.language || "en";
  const locale = supportLocales.find(
    (locale) => locale.key === browserLanguage.toLowerCase()
  );
  return locale ? locale.key : "en";
};
