import { GraphSession, SessionData } from "../models/session";
import { DataGraphType } from "../models/common";
import { TooltipFormatterContextObject } from "highcharts";
import { color } from "../constants/color";
import {
  calculateAverage,
  convertNumber,
  planeNumber,
  roundAverageNumber,
  roundNumber,
} from "./number";

export const getChartOption = ({
  categories,
  data,
  title,
  suffix,
  ambassadorAverageValue,
}: {
  categories: number[];
  data: DataGraphType[];
  title: string;
  suffix: string;
  ambassadorAverageValue?: number | null;
}) => {
  const series = [
    {
      name: "user",
      data: data.map((item) => ({
        y: item.y,
        marker: {
          fillColor:
            item.mode === "On-course"
              ? color.courseModeColor
              : color.userLineColor,
          symbol: item.mode === "On-course" ? "square" : "circle",
          borderColor: "white",
          width: 10,
          height: 10,
        },
      })),
      color: color.userLineColor,
      showInLegend: false,
    },
  ];
  if (typeof ambassadorAverageValue === "number") {
    series.unshift({
      name: "ambassador",
      data: data.map((_, index) => ({
        y: ambassadorAverageValue,
        marker: {
          fillColor: color.ambassadorLineColor,
          symbol: "circle",
          borderColor: "white",
          width: 10,
          height: 10,
        },
      })),
      color: color.ambassadorLineColor,
      showInLegend: false,
    });
  }
  return {
    chart: {
      type: "line",
      style: {
        fontFamily: "Akrobat",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      align: "left",
      margin: 20,
      widthAdjust: 0,
    },
    xAxis: {
      categories: categories,
      gridLineWidth: 0.5,
      visible: false,
      gridLineColor: "rgba(255, 255, 255, 0.2)",
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: undefined,
      visible: true,
      gridLineWidth: 0.5,
      gridLineColor: "rgba(255, 255, 255, 0.2)",
      labels: {
        format: `{value}${suffix === "&deg" ? "\u00B0" : " " + suffix}`,
        style: {
          color: "white",
        },
      },
    },
    series: series,
    tooltip: {
      backgroundColor: color.main,
      borderColor: "white",
      borderRadius: 6,
      borderWidth: 1,
      shadow: false,
      padding: 10,
      formatter(this: TooltipFormatterContextObject): string {
        const point = this.point;
        const pointIndex = point.index;
        const pointData = data?.[pointIndex] || {};
        const timeStamp = pointData.timestamp || "";
        let timeStampString = timeStamp;
        const timeStampArray = timeStamp ? timeStamp.split(" ") : [];
        if (timeStampArray.length) {
          const timeString = timeStampArray[1]?.slice(0, -3);
          if (timeString) {
            timeStampString =
              timeStampArray[0] +
              " " +
              timeString +
              " " +
              (timeStampArray[2] || "");
          }
        }
        const mode = pointData.mode;
        const totalSwingsCount = pointData.totalSwingsCount || 0;
        const club = pointData.club || "";
        let description = pointData.description || "";
        description =
          description.length < 100
            ? description
            : description.substr(0, 100 - 3) + "...";

        if (point?.series?.name === "ambassador") {
          return `<b>${point?.y}${
            suffix === "&deg" ? suffix : " " + suffix
          }</b>`;
        }
        return `<b>${point?.y}${
          suffix === "&deg" ? suffix : " " + suffix
        }</b><br/><span style="height: 1px;width: 100%; background: white"></span>${
          club ? "<span>" + club + "</span><br/>" : ""
        }<span>${timeStampString}</span><br/>${
          mode ? "<span>" + mode + " Mode</span><br/>" : ""
        }${
          description ? "<span>" + description + "</span><br/>" : ""
        }<span>${totalSwingsCount} ${
          totalSwingsCount > 1 ? "swings" : "swing"
        }</span>`;
      },
      style: {
        fontSize: 14,
        color: "white",
      },
    },
  };
};

export const getChartData = (
  data: SessionData[],
  sessionField: keyof SessionData,
  clubSelectedNumber: number[]
) =>
  data
    .map((session) => ({
      y: getOnCourseAverageValue(
        session,
        sessionField,
        clubSelectedNumber
      ) as number,
      timestamp: session.timestamp,
      totalSwingsCount: session.totalSwingsCount,
      club: session.club,
      mode: session.mode,
      description: session.description,
    }))
    ?.filter((item) => typeof item.y === "number" && !Number.isNaN(item.y));

export const getOnCourseAverageValue = (
  data: SessionData,
  sessionField: keyof SessionData,
  clubSelectedNumber: number[]
) => {
  let averageValue: null | number = data[sessionField] as number;
  if (
    data.mode === "On-course" &&
    clubSelectedNumber?.length &&
    data.averagesPerClub
  ) {
    const clubAverageNumberArray: number[] = [];
    clubSelectedNumber.forEach((club) => {
      if (
        data.averagesPerClub?.[club] &&
        typeof data.averagesPerClub[club]?.[sessionField] === "number"
      ) {
        clubAverageNumberArray.push(
          data.averagesPerClub[club][sessionField] as number
        );
      }
    });
    if (clubAverageNumberArray.length > 0) {
      averageValue = roundAverageNumber(
        calculateAverage(clubAverageNumberArray),
        sessionField
      ) as number;
    } else {
      averageValue = null;
    }
  }
  return averageValue;
};

export const getCategories = (data: number[]) => {
  let categories: number[] = [];
  for (let i = 0; i < data.length; i++) {
    categories.push(i + 1);
  }
  return categories;
};

export const getAllChart = (unitOfMeasure: string): GraphSession[] => [
  {
    id: "transition",
    ambassadorField: "transition",
    suffix: unitOfMeasure,
    title: "Average transition per session",
    graphTitle: "Transition",
    roundNumber: 1,
  },
  {
    id: "lengthOfBackswing",
    ambassadorField: "lengthOfSwingBackswing",
    suffix: unitOfMeasure,
    title: "Average length of backswing per session",
    graphTitle: "Length of backswing",
    roundNumber: 1,
  },
  {
    id: "tempoRatio",
    ambassadorField: "tempoRatio",
    suffix: ": 1",
    title: "Average ratio per session",
    graphTitle: "Tempo ratio",
    roundNumber: 1,
  },
  {
    id: "startToImpact",
    ambassadorField: "tempoStartToImpact",
    suffix: "s",
    title: "Average start to impact per session",
    graphTitle: "Start to impact",
    roundNumber: 2,
  },
  {
    id: "backSwingDuration",
    ambassadorField: "tempoBackswing",
    suffix: "s",
    title: "Average backswing duration per session",
    graphTitle: "Backswing duration",
    roundNumber: 2,
  },
  {
    id: "downSwingDuration",
    ambassadorField: "tempoDownswing",
    suffix: "s",
    title: "Average down swing duration per session",
    graphTitle: "Downswing duration",
    roundNumber: 2,
  },
  {
    id: "tempoPause",
    ambassadorField: "tempoPause",
    suffix: "s",
    title: "Average tempo pause per session",
    graphTitle: "Pause",
    roundNumber: 2,
  },
  {
    id: "maxHandSpeed",
    ambassadorField: "handSpeedMax",
    suffix: "mph",
    title: "Average max hand speed per session",
    graphTitle: "Max hand speed",
    roundNumber: 1,
  },
  {
    id: "maxHandSpeedDistanceToImpact",
    ambassadorField: "handSpeedMaxInDownswingDistanceToImpact",
    suffix: unitOfMeasure,
    title: "Average distance to impact per session",
    graphTitle: "Distance to impact",
    roundNumber: 0,
  },
  {
    id: "powerScorePredictedChs",
    ambassadorField: "powerScorePredictedCHS",
    suffix: "mph",
    title: "Average club head speed per session",
    graphTitle: "Club head speed",
    graphSubtitle: "Driver only",
    roundNumber: 1,
  },
  {
    id: "maxBackSwingWidth",
    ambassadorField: "backswingWidthMax",
    suffix: unitOfMeasure,
    title: "Average max backswing width per session",
    graphTitle: "Max backswing width",
    roundNumber: 0,
  },
  {
    id: "backSwingPlane",
    ambassadorField: "backswingPlane2D",
    suffix: "&deg",
    title: "Average backswing plane per session",
    graphTitle: "Backswing plane",
    roundNumber: 0,
  },
  {
    id: "IDDx",
    ambassadorField: "iddx",
    suffix: "&deg",
    title: "Average IDDx per session",
    graphTitle: "IDDx",
    roundNumber: 0,
  },
  {
    id: "IDDy",
    ambassadorField: "iddy",
    suffix: "&deg",
    title: "Average IDDy per session",
    graphTitle: "IDDy",
    roundNumber: 0,
  },
  {
    id: "steepeningShallowing",
    ambassadorField: "steepeningShallowing10cmInDownswing",
    suffix: "&deg",
    title: "Average steepening/shallowing per session",
    graphTitle: "Steepening / shallowing",
    roundNumber: 1,
  },
];

export const getAverageValue = (
  clubData: { [k: string]: number },
  field: string,
  unitOfMeasure?: string
) => {
  const data = clubData[field];
  if (typeof data !== "number") {
    return null;
  }
  switch (field) {
    case "transition":
    case "lengthOfSwingBackswing":
    case "lengthOfBackswing":
      return convertNumber({
        unitOfMeasure,
        data,
      });
    case "tempoRatio":
    case "tempoStartToImpact":
    case "tempoBackswing":
    case "tempoDownswing":
    case "tempoPause":
      return roundNumber({ data });
    case "handSpeedMax":
    case "powerScorePredictedChs":
    case "powerScorePredictedCHS":
      return roundNumber({ data, ratio: 2.23694 });
    case "handSpeedMaxInDownswingDistanceToImpact":
    case "handSpeedMaxDistanceToImpact":
    case "backswingWidthMax":
      return convertNumber({ data, unitOfMeasure, zeroDecimal: true });
    case "backswingPlane2D":
    case "iddx":
    case "iddy":
      return planeNumber({ data });
    case "steepeningShallowing10cmInDownswing":
      return planeNumber({ data, noZeroDecimal: true });
  }
};
