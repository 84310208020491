import React from "react";
import { loadMapApi } from "../utils/map";

interface MapProps {
  scriptLoaded: boolean;
}

const initState: MapProps = {
  scriptLoaded: false,
};

const MapContext = React.createContext<MapProps>(initState);
export const useMapContext = () => React.useContext(MapContext);

export const MapProvider = ({
  googleMapKey,
  children,
}: {
  googleMapKey: string;
  children: any;
}) => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  let googleMapScript = null;
  //   if (googleMapKey) {
  googleMapScript = loadMapApi(googleMapKey);
  googleMapScript.addEventListener("load", function () {
    setScriptLoaded(true);
  });
  //   }

  const value = React.useMemo(
    () => ({
      scriptLoaded,
    }),
    [scriptLoaded]
  );

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};
