import React, { Suspense, lazy } from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppProvider from "./contexts/AppProvider";
import { green } from "@mui/material/colors";
import ToastProvider from "./contexts/ToastProvider";
import AppLoading from "./components/AppLoading";
import { MapProvider } from "./contexts/MapProvider";

const HomePage = lazy(() => import("./Home"));
const LoginPage = lazy(() => import("./pages/auth/Login"));

const App = () => {
  const googleMapKey = !window.location.href.includes("data.dewizgolf.com")
    ? ""
    : (process.env.REACT_APP_GOOGLE_MAP_API_KEY as string) || "";

  const theme = createTheme({
    palette: { secondary: { main: green[600] } },
    typography: {
      fontFamily: `Akrobat`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AppProvider>
          <ToastProvider>
            <MapProvider googleMapKey={googleMapKey}>
              <Router>
                <Suspense fallback={<AppLoading />}>
                  <Switch>
                    <PrivateRoute exact path={["/"]} component={HomePage} />
                    <Route exact path={["/login"]} component={LoginPage} />
                  </Switch>
                </Suspense>
              </Router>
            </MapProvider>
          </ToastProvider>
        </AppProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
