export const color = {
  main: "#1B232A",
  grey: "rgba(255, 255, 255, 0.4)",
  yellow: "#F2D186",
  userLineColor: "#3EA4E8",
  ambassadorLineColor: "#F09943",
  black: "#1A1919",
  buttonColor: "#FAF1E1",
  courseModeColor: "#C84AA4",
  textGrey: "#C5C7C9",
  infoColor: "#FAE5B3",
  softBlack: "#202228",
  white: "#E7E8E9",
  green: "#00B388",
  grey_5: "#F9F9F9",
  button_secondary: "rgba(232, 221, 201, 0.88)",
};
