import { UOM } from "../FirebaseFunctions";
import { ClubAverage } from "../models/ambassador";
import { SessionData } from "../models/session";

export const round = (number: number, decimals: number) => {
  return parseFloat(number.toFixed(decimals));
};

export const getAverageNumber = (data: number[], roundNumber?: number) => {
  const dataFilter = data.filter((item) => item !== undefined && item !== null);
  if (!dataFilter.length) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < dataFilter.length; i++) {
    sum += data[i] || 0;
  }
  return round(
    sum / dataFilter.length,
    typeof roundNumber === "number" ? roundNumber : 1
  );
};

export const convertNumber = ({
  unitOfMeasure,
  data,
  zeroDecimal,
}: {
  unitOfMeasure?: string;
  data: number;
  zeroDecimal?: boolean;
}) => {
  if (unitOfMeasure === UOM.Imperial) {
    data /= 2.54;
  }
  data = round(100 * data, zeroDecimal ? 0 : 1);
  return data;
};

export const roundNumber = ({
  data,
  ratio,
}: {
  data: number;
  ratio?: number;
}) => (ratio ? round(ratio * data, 1) : round(data, 2));

export const planeNumber = ({
  data,
  noZeroDecimal,
}: {
  data: number;
  noZeroDecimal?: boolean;
}) => round((data * 180) / Math.PI, noZeroDecimal ? 1 : 0);

export const calculateAverage = (numbers: number[]) => {
  let total = 0;
  for (let i = 0; i < numbers.length; i++) {
    total += numbers[i];
  }
  return total / numbers.length;
};

export const roundAverageNumber = (
  number: number,
  field: keyof (ClubAverage & SessionData)
) => {
  switch (field) {
    case "transition":
    case "lengthOfSwingBackswing":
    case "lengthOfBackswing":
    case "handSpeedMax":
    case "maxHandSpeed":
    case "steepeningShallowing10cmInDownswing":
    case "steepeningShallowing":
    case "powerScorePredictedCHS":
    case "powerScorePredictedChs":
      return round(number, 1);
    case "tempoRatio":
    case "tempoStartToImpact":
    case "startToImpact":
    case "tempoBackswing":
    case "backSwingDuration":
    case "tempoDownswing":
    case "downSwingDuration":
    case "tempoPause":
      return round(number, 2);
    case "handSpeedMaxInDownswingDistanceToImpact":
    case "maxHandSpeedDistanceToImpact":
    case "backswingWidthMax":
    case "maxBackSwingWidth":
    case "backswingPlane2D":
    case "backSwingPlane":
    case "iddx":
    case "IDDx":
    case "iddy":
    case "IDDy":
      return round(number, 0);
  }
};
