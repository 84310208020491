import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const AppLoading = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="black"
    >
      <CircularProgress
        size={50}
        sx={{
          color: "white",
          margin: "auto",
        }}
      />
    </Box>
  );
};

export default AppLoading;
