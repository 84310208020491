export const allMode = [
  { id: "onCourse", value: "On-course" },
  { id: "none", value: "Discovery" },
  { id: "transition", value: "Transition" },
  { id: "tempo", value: "Tempo" },
  { id: "lengthOfBackswing", value: "Length of backswing" },
  { id: "distanceWedges", value: "DistWedges" },
  { id: "distWedgesLOBChallenge", value: "Distwedges challenge" },
];

export const allModeOptions = allMode.map((option) => option.value);
