import React, { useCallback, useContext, useMemo, useState } from "react";
import { Alert, AlertColor } from "@mui/material";
import ErrorIcon from "../assets/images/error.svg";
import { StyledSnackbar } from "./styles";

interface ToastDataType {
  message: string;
  type: AlertColor;
  open?: boolean;
}

interface ToastContextProps {
  showToast: (data: ToastDataType) => void;
}

const initialState: ToastContextProps = {
  showToast: () => null,
};

export const ToastContext =
  React.createContext<ToastContextProps>(initialState);

export const useToastContext = () => useContext(ToastContext);

const ToastProvider = ({ children }: { children: JSX.Element }) => {
  const [toastData, setToastData] = useState<ToastDataType>({
    message: "",
    type: "success",
    open: false,
  });
  const showToast = useCallback(
    (data: ToastDataType) => {
      setToastData({ ...data, open: true });
    },
    [setToastData]
  );

  const value = useMemo(
    () => ({
      showToast,
    }),
    [showToast]
  );

  const handleClose = () => {
    setToastData({ message: "", type: toastData.type, open: false });
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <StyledSnackbar
        open={toastData.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={toastData.type}
          sx={{ width: "100%" }}
          iconMapping={{
            error: <img src={ErrorIcon} alt="error" />,
          }}
        >
          {toastData.message}
        </Alert>
      </StyledSnackbar>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
