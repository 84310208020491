import React, { useCallback, useContext, useMemo, useState } from "react";
import { EAppTabs } from "../models/common";

interface AppContextProps {
  tab: EAppTabs;
  handleChangeTab: (tab: EAppTabs) => void;
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleCloseDrawer: () => void;
}

const initialState: AppContextProps = {
  tab: EAppTabs.DISCOVER,
  handleChangeTab: () => null,
  drawerOpen: false,
  toggleDrawer: () => () => null,
  handleCloseDrawer: () => null,
};

export const AppContext = React.createContext<AppContextProps>(initialState);

export const useAppContext = () => useContext(AppContext);

const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [currentTab, setCurrentTab] = useState<EAppTabs>(EAppTabs.DISCOVER);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = useCallback(
    (open: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    },
    [setDrawerOpen]
  );

  const handleCloseDrawer = useCallback(() => {
    if (drawerOpen) {
      toggleDrawer(false)();
    }
  }, [drawerOpen, toggleDrawer]);

  const handleChangeTab = useCallback(
    (tab: EAppTabs) => {
      setCurrentTab(tab);
      handleCloseDrawer();
    },
    [setCurrentTab, handleCloseDrawer]
  );
  const value = useMemo(
    () => ({
      tab: currentTab,
      handleChangeTab,
      drawerOpen,
      toggleDrawer,
      handleCloseDrawer,
    }),
    [currentTab, handleChangeTab, drawerOpen, toggleDrawer, handleCloseDrawer]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
