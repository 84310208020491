export enum EAppTabs {
  DISCOVER = "Discover",
  DOWNLOAD = "Download",
  SESSION = "Session",
}

export type Option = {
  title: string;
  value: string;
};

export type DataGraphType = {
  y: number;
  timestamp: string;
  totalSwingsCount: number;
  club: string;
  mode: string;
  description: string;
};
