import { Snackbar, styled } from "@mui/material";

export const StyledSnackbar = styled(Snackbar)({
  "& .MuiAlert-standardError": {
    backgroundColor: "#FFDCDC",
    "& .MuiAlert-message": {
      color: "#871D1D",
      fontFamily: "NotoSans",
      fontSize: 16,
      lineHeight: "24px",
    },
    "& .MuiAlert-icon": {
      color: "#871D1D",
      opacity: 1,
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
    "& .MuiAlert-action": {
      color: "#871D1D",
    },
  },
});
